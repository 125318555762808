<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          icon="mdi-account-details-outline"
          inline
          class="px-5 py-3"
          hover-reveal
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              My profile
            </div>
          </template>

          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-text-field
                    v-model="user.plan.planName"
                    label="Plan using"
                    prepend-icon="mdi-cloud-check-outline"
                    readonly
                    filled
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="user.username"
                    label="Username"
                    prepend-icon="mdi-account-outline"
                    readonly
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="user.email"
                    label="Email Address"
                    prepend-icon="mdi-email-outline"
                    readonly
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    ref="fullName"
                    v-model="user.fullName"
                    label="Full Name *"
                    prepend-icon="mdi-account-tie-outline"
                    :rules="[rules.fullName.notEmpty, rules.fullName.characters, rules.fullName.length]"
                    :counter="30"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    ref="groupName"
                    v-model="user.groupName"
                    label="Group (Optional)"
                    prepend-icon="mdi-account-group-outline"
                    :rules="[rules.group.characters]"
                    :counter="20"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    ref="phoneNumber"
                    v-model="user.phoneNumber"
                    label="Phone (Optional)"
                    prepend-icon="mdi-phone-outline"
                    :rules="[rules.phone.characters]"
                    :counter="13"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="loginTypes[user.loginType]"
                    label="Login Type"
                    prepend-icon="mdi-login-variant"
                    readonly
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="updateProfile"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import LocalStorageService from '../../../utils/auth'

  const localStorageService = LocalStorageService.getService()

  export default {
    data: function () {
      return {
        loading: false,
        loginTypes: ['FIDO2', 'QR', 'ALL'],
        showLogin: '',
        rules: {
          fullName: {
            characters: value => {
              // const v = value.replace(/^\s+|\s+$/, '')
              const pattern = /^(?=[a-zA-Z0-9 ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\u0065-\u0309\u0065-\u0301\u0065-\u0300\u0065-\u0323\u0065-\u0303\u00EA-\u0309\u00EA-\u0301\u00EA-\u0300\u00EA-\u0323\u00EA-\u0303\u0079-\u0309\u0079-\u0301\u0079-\u0300\u0079-\u0323\u0079-\u0303\u0075-\u0309\u0075-\u0301\u0075-\u0300\u0075-\u0323\u0075-\u0303\u01B0-\u0309\u01B0-\u0301\u01B0-\u0300\u01B0-\u0323\u01B0-\u0303\u0069-\u0309\u0069-\u0301\u0069-\u0300\u0069-\u0323\u0069-\u0303\u006F-\u0309\u006F-\u0301\u006F-\u0300\u006F-\u0323\u006F-\u0303\u01A1-\u0309\u01A1-\u0301\u01A1-\u0300\u01A1-\u0323\u01A1-\u0303\u00F4-\u0309\u00F4-\u0301\u00F4-\u0300\u00F4-\u0323\u00F4-\u0303\u0061-\u0309\u0061-\u0301\u0061-\u0300\u0061-\u0323\u0061-\u0303\u0103-\u0309\u0103-\u0301\u0103-\u0300\u0103-\u0323\u0103-\u0303\u00E2-\u0309\u00E2-\u0301\u00E2-\u0300\u00E2-\u0323\u00E2-\u0303\u0045-\u0309\u0045-\u0301\u0045-\u0300\u0045-\u0323\u0045-\u0303\u00CA-\u0309\u00CA-\u0301\u00CA-\u0300\u00CA-\u0323\u00CA-\u0303\u0059-\u0309\u0059-\u0301\u0059-\u0300\u0059-\u0323\u0059-\u0303\u0055-\u0309\u0055-\u0301\u0055-\u0300\u0055-\u0323\u0055-\u0303\u01AF-\u0309\u01AF-\u0301\u01AF-\u0300\u01AF-\u0323\u01AF-\u0303\u0049-\u0309\u0049-\u0301\u0049-\u0300\u0049-\u0323\u0049-\u0303\u004F-\u0309\u004F-\u0301\u004F-\u0300\u004F-\u0323\u004F-\u0303\u01A0-\u0309\u01A0-\u0301\u01A0-\u0300\u01A0-\u0323\u01A0-\u0303\u00D4-\u0309\u00D4-\u0301\u00D4-\u0300\u00D4-\u0323\u00D4-\u0303\u0041-\u0309\u0041-\u0301\u0041-\u0300\u0041-\u0323\u0041-\u0303\u0102-\u0309\u0102-\u0301\u0102-\u0300\u0102-\u0323\u0102-\u0303\u00C2-\u0309\u00C2-\u0301\u00C2-\u0300\u00C2-\u0323\u00C2-\u0303]+$)/u
              return pattern.test(value) || 'Invalid name'
            },
            length: v => (v && v.length <= 30) || 'Max length is 30 characters',
            notEmpty: v => !!v || 'Required',
          },
          group: {
            characters: value => {
              const pattern = /^$|^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
              return pattern.test(value) || 'Invalid group name'
            },
          },
          phone: {
            characters: value => {
              const pattern = /^$|^(\+[0-9]{2}|0)([0-9]{9,10}$)/
              return pattern.test(value) || 'Invalid phone number'
            },
          },
        },
        user: {},
        urls: {
          get: '/v1/api/users',
          put: '/v1/api/users/update',
        },
      }
    },

    created () {
      this.getUserInfo()
    },

    methods: {
      async getUserInfo () {
        this.loading = true // the loading begin
        const user = localStorageService.getUsername()
        const userData = JSON.parse(user)
        if (userData.username) {
          await axios.get(`${this.urls.get}/${userData.username}`)
            .then(response => {
              // console.log('response', response)
              this.user = response.data.data
            }).catch(e => {
              this.handleErrors(e)
            }).finally(() => (this.loading = false)) // set loading to false when request finish
        }
      },

      async updateProfile () {
        if (!this.$refs.fullName.validate() ||
          !this.$refs.groupName.validate() ||
          !this.$refs.phoneNumber.validate()) {
          return
        }
        const data = {
          fullName: this.user.fullName,
          groupName: this.user.groupName,
          phoneNumber: this.user.phoneNumber,
        }
        axios.patch(`${this.urls.put}/${this.user.username}`, data)
          .then(response => {
            // console.log('reg response: ', response)
            // const token = response.data.access_token
            // const user = response.data.username
            this.show_message('success', 'Update success!')
            this.user = response.data.data
            const newUser = {
              username: this.user.username,
              fullName: this.user.fullName,
            }
            localStorageService.setUsername(JSON.stringify(newUser))
            this.$store.commit('SET_USER', JSON.stringify(newUser))
          })
          .catch(e => {
            this.handleError(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      handleError (error) {
        if (error.response === undefined) {
          this.show_message('error', error.toString())
        } else if (!error.response.data.success) {
          if (error.response.data.errorMessage) {
            this.show_message('error', error.response.data.errorMessage)
          } else if (error.response.data.validations) {
            this.show_message('error', error.response.data.validations[0].message)
          } else {
            this.show_message('error', error.response.data.message)
          }
        } else {
          this.show_message('error', 'api service not available!')
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>
